.menu_mobile .ant-menu-title-content {
  color: black;
}

.ant-menu-title-content {
  color: #fff;
  /* color: #0c78bee6; */
  /* font-weight: 800; */
}
.ant-menu-submenu-popup .ant-menu-title-content {
  color: #000;
}
.ant-menu-submenu-popup
  .ant-menu-item-group-list
  li:hover
  .ant-menu-title-content {
  color: #0c78bee6;
}
.ant-menu-submenu-popup > ul > li:hover > .ant-menu-title-content {
  color: #0c78bee6;
}
.ant-menu-item-group-title {
  color: #ff741a;
  font-weight: 700;
  font-size: large;
}
.ant-menu-item
  .ant-menu-item-active
  .ant-menu-item-selected
  .ant-menu-item-only-child {
  color: #ff741a;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after {
  border-bottom: #ff741a;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after {
  border-bottom: #ff741a;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after {
  border-bottom: #ff741a;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after {
  border-bottom: 2px solid #ff741a;
}
.ant-menu-item .ant-menu-item-active .ant-menu-item-only-child {
  color: blueviolet;
}
