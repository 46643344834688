@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Manrope", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.blogsection img {
  width: 100% !important;
  height: auto !important;
}
.blogsection ul {
  padding-left: 40px;
}
.blogsection .news-content * {
  font-size: 14px !important;
  font-family: "Manrope", sans-serif !important;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
